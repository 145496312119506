import {Himal} from "./statement-himal.js";
import {Chris} from "./statement-chris.js";


export const Principals = [
    {
        name: "Himal Karmacharya",
        slug: "himal",
        position: "President of Leapfrog, Patron of Adhyayan School",
        photo: "person_himal.jpg",
        quote: "I am excited about using technology to make learning exciting and effective in Adhyayan School. The arc of my life has been shaped by education and I hope that will be true for the future generation of Nepal. I am inspired by what Nelson Mandela said \"Education is the most powerful weapon which you can use to change the world.\"",
        content: Himal
    },
    {
        name: "Chris Sprague",
        position: "CEO of Leapfrog,  Vice-Principal Global Education and Technology of Adhyayan School",
        slug: "chris",
        photo: "person_chris.jpg",
        quote: "For the past eight years, I’ve had the privilege of getting to know, and love Nepal.  I’ve worked alongside talented and inspiring people who are the lifeblood and leaders of Leapfrog.  I’m grateful for the chance to leverage my learnings in EdTech and Project-Based Learning to help Adhyayan foster the next generation of engineers and leaders.",
        content: Chris
    },
    {
        name: "Chandika Bhandari",
        slug: "chandika",
        position: "President of Leapfrog, Advisor for Adhyayan School",
        photo: "person_chandika.jpg",
        quote: "",
        content: null
    }
]