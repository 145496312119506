import { Row, Col } from "./Container";

export default function ModalCard ( { item }) {
    return <div className="border-bottom mt-lg-4 mb-4 pb-5">

    <Row align="start">
        <Col size={{ lg: 3, md: 5 }}>
            <img src={ require("../images/" + item.photo )} height={ 108 } width={ 108 } alt={ item.name } className="w-100 h-100 mb-4" />
        </Col>
        <Col size={{ lg: 9, md: 12 }}>
            <div className="position-relative">
                <span className="text-muted position-absolute quote-mark">〝</span>
                <blockquote className="fs-5 fw-light">
                    { item.quote }
                </blockquote>
                <p className="m-0 fw-bold">{ item.name }</p>
                <small>{ item.position }</small>
            </div>
        </Col>
    </Row>


    </div>
}