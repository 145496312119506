export function Container ({ children, isFluid }) {
    let classNames = [
        "container py-md-5 py-4",
        `${ isFluid ? "container-fluid" : "" }`
    ]
    return <div className={ classNames.join(" ")}>
        { children }
    </div>
}

export function Row ({ justify, align, children, className }) {
    let classNames = [
        "row",
        `${ className ? className : "" }`,
        `${ justify ? "justify-content-" + justify : "" }`,
        `${ align ? "align-items-" + align : "" }`
    ]
    return <div className={ classNames.join(" ")}>
        { children }
    </div>
}

export function Col ({ size, offset, children, order, className }) {
    let classNames = [
        "col-12",
        `${ className ? className : "" }`
    ]

    for( const key in size) {
        classNames.push(`col-${ key }-${ size[key] }`)
    }

    for( const key in order) {
        classNames.push(`order-${ key }-${ order[key] }`)
    }

    for( const key in offset) {
        classNames.push(`offset-${ key }-${ offset[key] }`)
    }

    return <div className={ classNames.join(" ")}>
        { children }
    </div>
}