export default function Quote ({ item }) {
    return <div className="p-5 position-relative">
        <span className="text-muted position-absolute quote-mark">〝</span>
        <blockquote className="fw-light fs-5">{ item.quote }</blockquote>

        <div className="d-flex mt-4">
            <img src={ require("../images/" + item.photo )} height={ 56 } width={ 56 } alt={ item.name } className="rounded-circle" />
            <div className="flex-fill mx-3">
                <p className="m-0 fw-bold">{ item.name }</p>
                <small>{ item.position }</small>
            </div>
        </div>
    </div>
}