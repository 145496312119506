import { Container, Row, Col } from "../components/Container"

import HeroImg from "../images/top_hero_img.jpg"
import CobrandLogo from "../images/lf_ad_cobrand.png"

export default function Header ({ onClick }) {
    return <Container>
        <Row align="center" justify="center" className="my-md-5">
            <Col size={{ md: 6 }}>
                <img src={ CobrandLogo } alt="Leapfrog x Adhyayan" height={ 42 } className="mb-5" />
                <h1><a href="https://lftechnology.com" rel="noreferrer" target="_blank">Leapfrog</a> partners with <a href="https://adhyayan.edu.np" target="_blank" rel="noreferrer">Adhyayan School</a> to innovate education in Nepal with technology, design-thinking, and project-based learning</h1>
                <button onClick={ onClick && onClick } className="mt-5 btn btn-primary btn-lg btn-success">Leapfrog's Education Mission</button>
            </Col>

            <Col size={{ md : 6 }}>
                <img src={ HeroImg } width={ 500 } height={ 500 } alt="A student working on electrical circuit" className="img-fluid mt-5 mt-md-0 rounded-circle-" />
            </Col>
        </Row>
    </Container>
}