export const mission = [
    "Times are changing fast! The jobs, roles, and careers that exist today won’t exist tomorrow. We have already seen the emergence of new roles that didn’t exist five years ago. At Leapfrog, we are at the front row seat of that change. We have roles such as user experience designer, machine learning engineer, and social media manager that didn’t exist a few years ago. We use technologies like TensorFlow, GoLang, and Airflow that have only recently risen to prominence. In fact, the technology industry is defined by the continuous shift from old technologies to emerging technologies and old roles to new roles. As a company, we need people to have fundamental habits to thrive in the fast-changing world.",

    "When we observe Leapfroggers who embrace and thrive in these new roles and technologies, we see the common habits are a habit of building things, self-learning, design thinking, and critical thinking. At the core, they are problem solvers. When we look back, most of them didn’t get those habits from the education system they grew up in. They are a rare bunch of people who stumble upon those habits through innate luck, serendipity or mentoring.",

    "We realize that if we are to build a company that can last more than 100 years then we can’t simply rely on serendipity. There needs to be a structure that supports building the habits that make technologists and leaders successful. We have to participate in building the education system that enables current and future children to build the habit of tinkering, self-learning, design thinking, and critical thinking. For us, it is not only the right thing to do for society but also the right business decision to invest in for the long term to create the potential population of future leaders of Leapfrog. That’s why we’ve decided to invest our time, knowledge, and financial resources to help progress education in Nepal.",

    "That journey begins with Adhyayan School, a school that shares our values and principles. We have partnered with Adhyayan, whose mission is to “innovate education in Nepal through the use of technology, infusion of design thinking, and the blending of the best of holistic approaches to education.” In Adhyayan, we see a great opportunity to impact the future of society. We see ourselves as supporters and enablers to the leaders such as Adhyayan school to provide a new pedagogical approach to education for our children.",

    "At Leapfrog, we believe that technology and design thinking don't just enable faster learning and greater success when we build products with our US clients, but that those same techniques can empower faster and more successful learning for the next generation of leaders, engineers, and problem-solvers in Nepal. We believe that our perspective and skills complement Adhyayan School’s mission and that by partnering we can achieve something great that we couldn’t have done alone."
]