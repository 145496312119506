import { Col, Row } from "./Container";

export default function Program ( { item, number }) {
    return <Row justify="center" align="stretch" className="my-5">
        <Col size={{ md: 6 }} order={{ lg: item.order }}>
            <div className="p-5 bg-light h-100">
                <p className="fs-1 text-muted">#{ number }</p>
                <h3>{ item.title }</h3>
                <p className="">{ item.text }</p>

                { item.trigger && <hr /> }
                { item.trigger && <p className="text-muted fs-6 fw-bold">{ item.trigger }</p> }
                { item.moreinfo && <a href={ item.moreinfo } target="_blank" rel="noreferrer" className="btn btn-outline-secondary">Learn more</a>}
            </div>
        </Col>
        <Col size={{ xl: 4, md: 6 }} order={{ md: item.order === 2 ? 1 : 2 }}>
            <img src={ require("../images/" + item.image ) } width={ 600} height={ 500} className="img-fluid" alt={ item.title } />
        </Col>
    </Row>
}