export const Collab = [
    {
        title: "Empower project based learning",
        image: "segment_001.jpg",
        text: "In Adhyayan, we see a great opportunity to impact the future of society. We are powering a pedagogical approach to education for our children. We are collaborating to design courses that contain techniques and technologies we use today - selecting only the very best resources available, providing content tailored to problem-solving, tinkering, and programming.",
        order: 2,
        trigger : "Leapfrog’s training program that trains hundreds of engineer per year",
        moreinfo: "https://www.lftechnology.com/internship"
    },
    {
        title: "Provide mentorship to students and teachers",
        image: "segment_002.jpg",
        text: "Adhyayan students and teachers will benefit from the mentorship from our folks at Leapfrog. Students will get access to the latest and state of the art technologies, processes, and approaches to engaging learners.",
        order: 1,
        trigger: "",
        moreinfo: null
    },
    {
        title: "Co-organize Events and Workshops",
        image: "segment_003.jpg",
        text: "We will help Adhyayan organize global events in Nepal so that students and teachers get to participate and collaborate with students all around the world.  ",
        order: 2,
        trigger: "",
        moreinfo: null
    },
    {
        title: "Accelerate Technology Adoption",
        image: "segment_004.jpg",
        text: "We will advise and help implement technology to improve the delivery of education at Adhyayan. As a business, we believe that technology and design thinking doesn’t just enable faster learning and greater success for our clients, but also faster and more successful learning for the next generation of leaders and engineers in Nepal.  We will leverage our expertise to help Adhyayan teachers and students to use the best of technology and design thinking practices to guide their students to more engaged critical thinkers and problem solvers on a pathway to successful careers.",
        order: 1,
        trigger: "",
        moreinfo: null
    }
];