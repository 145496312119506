import { useState } from "react"

import Header from "./views/Header"
import Footer from "./views/Footer"

import { Container, Row, Col } from "./components/Container";
import Quote from "./components/Quote";
import Program from "./components/Program";

import { Collab } from "./constants/how-we-support"
import { Principals } from "./constants/principals"
import { mission } from "./constants/mission"

import "./css/style.css"
import Modal from "./components/Modal";

function App() {

  const [modal, setModal] = useState(undefined)

  return (
    <>

      <Modal data={ modal } onClose={ () => setModal(undefined) } />

    <main>
      <Header onClick={ () => setModal({
        title: "Leapfrog's Mission with Education",
        content: mission
      }) } />

      <Container>
        <Row>
          <Col size={{ lg : 6 }}>
            <div className="bg-light p-5">
                <h2 className="fw-light">Our goal is to enhance the student learning experience at Adhyayan School</h2>
                <p>We plan to invest our knowledge, skills, and network to use the best of technology, design thinking, and learning by doing practices. Adhyayan is building a place to guide their students to become engaged critical thinkers and problem solvers on a pathway to successful careers.</p>
                <a href="http://adhyayan.edu.np/" className="btn btn-danger mt-4" target="_blank" rel="noreferrer">Learn more about Adhyayan School</a>
            </div>
          </Col>
          <Col size={{ lg : 6, md: 10 }}>
            <Quote item={ Principals[0] } />
          </Col>
        </Row>
      </Container>



      <Container>
        <Row justify="center">
          <Col size={{ md : 10 }}>
            <h2 className="text-center fs-2 fw-bold">Learn more about how we support Adhyayan</h2>
          </Col>
        </Row>
        { Collab.map(( item, index ) => <Program item={ item } number={ index + 1 } key={ index } />)}
      </Container>


      <Container>
        <Row justify="center">
          <Col size={{ md : 10 }} className="mb-5">
            <h2 className="text-center fs-2 fw-bold">The best minds and employees of Leapfrog power the partnership with Adhyayan</h2>
          </Col>

          <Col size={{ lg : 6, md: 10 }}>
            <Quote item={ Principals[1] } />
          </Col>

          <Col size={{ lg : 6 }}>
            <div className="bg-interns">
              <div className="bg-interns-dark text-white">
                  <p className="text-success text-uppercase fw-bold fs-6">Best minds in Nepal</p>
                  <p className="fw-light fs-3">We believe in creating an education system that builds the habit to thrive in both the careers of today and the careers that don’t exist yet</p>
              </div>
            </div>
          </Col>
        </Row>
      </Container>




      <Container>
        <Row justify="center" className="my-5">
          <Col size={{ md : 10 }}>
            <h2 className="text-center fs-2 fw-bold">Leaders who have achieved excellence in education and business pledge to play an active role at Adhyayan</h2>
          </Col>

          { Principals.map(( item, index ) => <Col size={{ lg : 4, sm: 6 }} key={ index }>
              <div className="p-5">
                <img src={ require("./images/" + item.photo )} height={ 72 } width={ 72 } alt={ item.name } className="rounded-circle mb-4" />
                <h4 className="fw-bold">{ item.name }</h4>
                <p>{ item.position }</p>
                { item.content && <button className="btn btn-outline-secondary" onClick={ () => setModal({ title: item.name, info: item, content: item.content}) }>Read story</button>}
              </div>
            </Col>)}
        </Row>
      </Container>


      <Footer />

      </main>

    </>
  );
}

export default App;
