import { useEffect } from "react"

import { Container, Row, Col } from "./Container"
import ModalCard from "./ModalCard"

export default function Modal ({ onClose, data }) {

    useEffect(() => {

        const handleKeyPress = (event) => {
            if (event.key === "Escape") {
                onClose()
            }
        }

        if( data ) {
            document.body.classList.add("modal-open")
        } else {
            document.body.classList.remove("modal-open")
        }


        document.addEventListener('keydown', handleKeyPress);
        return () => {
          document.removeEventListener('keydown', handleKeyPress);
        };
    }, [onClose, data])

    return <div className={`modal modal-sheet ${ data ? "fade show d-block" : "" }`} aria-modal="true" tabIndex="-1" role="dialog">
        <div className="modal-dialog modal-xl modal-fulslscreen" role="document">

            { data && <div className="modal-content rounded-4 shadow">
                <div className="modal-header">
                    <div>
                        <h5 className="modal-title">{ data.title }</h5>
                        { data.info && <small>{ data.info.position }</small> }
                    </div>
                    <button type="button" onClick={ onClose && onClose } className="btn-close" data-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body p-0">
                    <Container>
                        <Row justify="center">
                        <Col size={{ lg: 8, sm: 10 }}>
                            { data.info && <ModalCard item={ data.info } />}
                            <div className="p-0">
                                { !data.info && <h2 className="mb-4">{ data.title }</h2> }
                                { data.content && data.content.map(( item, index) => <p key={ index }>{ item }</p>)}
                            </div>
                        </Col>
                        </Row>
                    </Container>
                </div>
            </div> }
        </div>
    </div>
}