import { Col, Container, Row } from "../components/Container";

export default function Footer () {
    return <footer className="bg-dark p-5 text-center text-white">
        <Container>
        <Row justify="center">
            <Col size={{ sm: 12, md: 8 }}>
                <div className="mx-auto">
                <img src={ require("../images/tadpole_guild.png" )} height={ 72 } alt="A baby tadpole" className="mb-3" />

                    <p className="fs-4 m-0">Give your children the opportunity to thrive in the future by enrolling them at Nepal’s first design thinking and technology-focused school (d.tech school).</p>

                    <a href="http://adhyayan.edu.np/" target="_blank" rel="noreferrer" className="btn btn-danger btn-lg my-5">Learn more about Adhyayan School</a>

                    <p className="text-light">If you have more questions about the partnership between Leapfrog and Adhyayan then please email us at info@lftechnology.com</p>
                </div>
            </Col>
        </Row>
    </Container>
    </footer>
}